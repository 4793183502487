import LazyLoad from "vanilla-lazyload";
import fitvids from 'fitvids'
import sal from 'sal.js'
import '@slightlyoff/lite-vimeo'

sal();

if (document.readyState !== 'loading') {
		eventHandler();
	} else {
		document.addEventListener('DOMContentLoaded', eventHandler );
}

function eventHandler(){
	var lazyLoadInstance = new LazyLoad({
		// Your custom settings go here
	});

	lazyLoadInstance.update();

	if (document.getElementById('vid_wrap') != null) {
	var mv = null;
	mv = document.getElementById('vid_wrap');
	fitvids(mv);
	}

}